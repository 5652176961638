<template>
  <div class="card" ref="card" @click="goToProject(project.id)">

    <div class="img-container">
      <img :src="require(`@/assets/${project.img}`)" alt="Project Image">
    </div>
  
    <div class="description">
      <p class="techno">{{ project.techno }}</p>
      <h3 class="title">{{ project.title }}</h3>
      <p class="project-description" v-if="!hideDescription">{{ project.description }}</p>
      <div class="git-link" v-if="!hideGitLink">
        <img class="truc" src="../assets/github.png" alt="">
        <button>Visiter le projet</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ProjectCard',
  props: {
    project: Object,
    hideDescription: Boolean,
    hideGitLink: Boolean,
  },

  mounted() {
    this.$nextTick(() => {
      const cardWidth = this.$refs.card.clientWidth;
      this.$emit('updateWidth', cardWidth);
    });
  }, 
  methods: {
    goToProject(projectId) {
      console.log("Routing to project with ID:", projectId);
      this.$router.push({ name: 'projectView', params: { id: projectId } });
    },
  },
};
</script>

 
 <style scoped>
*{
 padding: 0;
 margin: 0;
}
.card {
  position: relative;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 10px solid #1E90FF;
  border-right: 10px solid #1E90FF;
  padding: 3em;
 gap: 1.5em;
 background-color: #29282E;
}

.card:hover {
  transform: translateY(-10px); /* Déplace la carte de 10 pixels vers le haut */
}

.img-container{
  width: 100%;
}
.card div img {
width: 100%;
border-radius: 12px;
height: 100%;
}
.img-container img {
  object-fit: cover;
  max-width: 100%;
  height: 35vh;
  max-height: 35vh;
}
.description {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items:flex-start ;
  text-align: left;
  gap: 5px;
}

.description .techno{
  opacity: 0.65;
font-weight: bold;
color: white;
}

.description h3{
  color: white;
  font-size: 3em;
  margin-bottom: 0.2em;
}
.project-description{
  margin-bottom: 1em;
  font-size: 1.3em;
  width: 90%;
  color: white;
}

.git-link{
  display: flex;
  height: auto;
  width: auto;
  gap: 1em;
}


.git-link button{
  width: 12em;
  color: white;
  background-color: #1E90FF;
  opacity: 0.9;
  border-radius: 10px;
  font-size: 1em;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.git-link .truc{
  height: 3em;
  width: 3em;
}


/* Pour les écrans moyens - 2 colonnes */
@media screen and (max-width: 1024px) {
  .card {
    flex-basis: calc(50% - 20px); /* Pour 2 colonnes */
  }
}

/* Pour les petits écrans - 1 colonne */
@media screen and (max-width: 820px) {
  .card {
    flex-basis: 100%; /* Pour 1 colonne */
  }

  .card{
    padding: 1em;
    border-bottom: none;
    border-right: none;
    border: solid 1px #1E90FF;
    z-index: 10;
  }
  .img-container img {
  max-width: 100%; /* Ajustez la largeur maximale si nécessaire */
  max-height: 100%; /* Ajustez la hauteur maximale si nécessaire */
  display: block; /* Élimine tout espace blanc en dessous de l'image */
  margin: 0 auto; /* Centre l'image horizontalement */
  object-fit: cover; /* Assurez-vous que l'image couvre la zone désignée sans se déformer */
}
  div .description {
    display: flex;
    align-items: center;
  }
  .img-container{
    display: none;
  }
  .description h3{
    font-size: 2em;
    text-align: center;
    align-items: center;
  }
  .project-description{
    font-size: 1.1em;
    text-align: center;
  }

  .git-link img{
    display: none;
  }
  button{
    padding: 0.8em;
  }
}
 </style>