<template>
  <div id="app">
    <AppNavbar />

    <router-view v-slot="{ Component }" :key="$route.fullPath">
			<transition name="page-slide" mode="out-in" >
				<Component :is="Component" />
			</transition>
		</router-view>
    <AppFooter/>
  </div>


</template>

<script>
import AppNavbar from './components/AppNavbar.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppFooter
  },
  data() {
    return {
      transitionOpen: false,
    };
  },

   
 
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
*{
  margin: 0;
  padding: 0;
}
html, body {
  overflow-x: hidden;
}

.transition {
  position: fixed;
  top: 0;
  left: -100%; /* Commence en dehors de l'écran */
  width: 100%;
  height: 100%;
  background: blueviolet; /* Couleur de fond du rideau */
  z-index: 1000; /* Assurez-vous qu'il est au-dessus de tout */
  transition: left 0.5s ease; /* Durée et type d'animation */
}

/* Classe pour déplacer le rideau */
.transition-active {
  left: 0%; /* Déplace le rideau vers la droite */
}

.page-slide-enter-active,
.page-slide-leave-active {
  transition: 600ms ease all;
}

.page-slide-enter-from,
.page-slide-leave-to {
  opacity: 0;
  transform: translateY(60px);
}
</style>