import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueGtag from 'vue-gtag';

const app = createApp(App);

app.use(router);

// Intégration de vue-gtag pour Google Analytics
app.use(VueGtag, {
  config: { id: 'G-GKYWQLNY6W' }
}, router);

app.mount('#app');