import HomeView from './views/HomeView.vue';
import ProjectView from './views/ProjectView.vue';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'homeView',
    component: HomeView
  },
  {
    path: '/project/:id',
    name: 'projectView',
    component: ProjectView,
    props: true // Permet de passer l'ID en tant que prop au composant
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { el: to.hash };
    } else {
      return { top: 0 };
    }
  }
});

export default router;