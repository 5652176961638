<template  >
    <div class="container" :style="{ backgroundColor: project.background}">

        <section class="start">

          <div class="presentation">
            <h1 :style="{ color: project.titre }">{{ project?.title }}</h1>
            <h3 :style="{ color: project.texte }">Présentation</h3>
            <p :style="{ color: project.texte }">{{ project?.descriptionView }}</p>
          </div>
          <div class="iphone">
            <img :src="project.imgDesciption" alt="">
          </div>
          <div class="function">
            <div class="space"><p class="number" ref="countFonctionnalite" :style="{ color: project.titre }">+0</p><h3>Fonctionnalités</h3></div><!-- {{ project?.nbFonctionnalite }} -->
            <div class="space"><p class="number" ref="countPage" :style="{ color: project.titre }">+0</p><h3>Pages</h3></div>
            <div class="space"><a :href="project.gitLink" class="gitLink"><img src="../assets/githubProject.png" alt=""></a></div>
          </div>

        </section>
        <section class="container-img">
          <img class="navbar" :src="project.img1" alt="">
          <div class="img-duo">
            <img v-if="project.img2" class="component" :src="project.img2" alt="">
            <img class="homeview" :class="{'full-width': !project.img2}" :src="project.img3" alt="">
          </div>
          <img v-if="project.img4" class="footer" :src="project.img4" alt="">
        </section>
    </div>
  </template>
  
  <script>

  import projects from '../data/projectsData';

  export default {
    components: {

  },
    name: 'ProjectView',
    props: {

    id: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    project() {
    console.log("Route ID:", this.$route.params.id);
    const projectFound = projects.find(p => p.id === parseInt(this.$route.params.id));
    console.log("Project found:", projectFound);
    return projectFound;
  }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.project) {
        this.animateValue(this.$refs.countFonctionnalite, 0, this.project.nbFonctionnalite, 1000);
        this.animateValue(this.$refs.countPage, 0, this.project.nbPage, 1000);
      }
    });
  },
  methods: {
    animateValue(obj, start, end, duration) {
      let startTime = null;

      const step = (currentTime) => {
        if (!startTime) {
          startTime = currentTime;
        }
        const progress = currentTime - startTime;
        const fraction = progress / duration;
        const current = Math.round((end - start) * fraction + start);

        obj.textContent = "+" + (current > end ? end : current);
        if (progress < duration) {
          window.requestAnimationFrame(step);
        }
      };

      window.requestAnimationFrame(step);
    }
  }
};
</script>
  
  <style scoped>
    *{
      margin: 0;
      padding: 0;

      
    }

  .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centrer verticalement si le conteneur a une hauteur définie */
  text-align: center; /* Centrer le texte dans tous les enfants directement */

}

.presentation h1{
  font-size: 4em;
  margin-top: 30px;
  text-align: left;
  margin-bottom: 0.7em;
}
.start{
  margin-top: 70px;
  padding-top: 50px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
 
.presentation{
  width: 50%;
  font-size: 25px;
}
.presentation h3{
  text-align: left;
  margin-bottom: 1em;
}
.presentation p{
  text-align: left;
}
.function h3{
  opacity: 0.7;
  
}
.space{
  margin-bottom: 5em;

}
.space a img{
  width: 80%;
  opacity: 0.9;
}


.function {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  width: 20%;
}
.function p {
font-weight: bold;
}
    .number{
      font-size: 4.5em;
    }
.iphone{
  width: 20%;
}
.iphone img{

  width: 100%;
}
.img-container img {
  max-width: 100%; 
  max-height: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}
.function .gitLink img {
    width: 40%; 
    height: auto; 
  }

/* -------------------- IMG -------------------- */

.container-img{
  margin-top: 10%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.5em;
}
.navbar{
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
}
.img-duo{
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100vh;
}
.component{
border-radius: 10px;
width: 100%;
}
.homeview{
  border-radius: 10px;
  width: 100%;
}
 .component, .homeview {
  border-radius: 10px;
  width: calc(50% - 5px);
  height: 100%;
} 
.full-width {
  width: 100% ;
}
@media screen and (max-width: 1300px){
  .container{
    height: 100%;
  }
  .iphone{
    display: none;
  }
  .number{
    display: none;
  }

  .function h3{
    display: none;

  }
  .start{
      flex-direction: column;
      margin-top: 70px;
    }
    .presentation h1{
      margin-top: 2.5em;
    font-size: 3em;
    text-align: center;
    margin-bottom: 0.5em;

  }
  .presentation{
      width: 100%;
    }
    .presentation h1{
      margin-top: 0;
    }
    .container-img{
      display: none;
    }
    .space{
      margin-bottom: 1em;
    }
    .function .gitLink img{
      width: 100%;
      height: auto;
    }
}
@media screen and (max-width: 820px){
  .number{
    display: none;
  }
  .function h3{
    display: none;

  }
  .start{
      flex-direction: column;
      margin-top: 70px;
    }

  .presentation h1{
    font-size: 2em;
    text-align: center;
    margin-bottom: 0.5em;

  }
  .presentation h3{
    font-size: 1.2em;
    text-align: center;
  }
  .presentation p{
    font-size: 0.9em;
    text-align: center;
  }
      .function {
      width: 60%;
      height: auto;
    }
    .function .gitLink img{
      width: 50%;
      height: auto;
    }
}
  </style>