<template>
     <nav class="navbar" :style="{ backgroundColor: project.navbar || '#19181C'}">
    <div class="navbar-brand">
      <a href="https://enzopoulain.fr/"><p class="logoNav" :style="{ color: project.logo || 'white'}">full stack.</p></a>
    </div>
    <button class="nav__hamburger" @click="toggleNav">
      <span class="nav__hamburger__line"></span>
      <span class="nav__hamburger__line"></span>
      <span class="nav__hamburger__line"></span>
    </button>
    <ul class="navbar-links" :class="{ 'active': navActive }">
        <li><a href="/#section1" :style="{ color: project.texteNav || 'white'}">Présentation</a></li>
        <li><a href="/#section2" :style="{ color: project.texteNav || 'white'}">À propos</a></li>
        <li><a href="/#section3" :style="{ color: project.texteNav || 'white'}">Porfolio</a></li>
        <li><a href="/#section4" class="contact-btn"  :style="{ backgroundColor: project.button || '#1E90FF'}">Contact</a></li>
      </ul>
    </nav>
  </template>
  
  <script>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import projects from '../data/projectsData';
  export default {
    props: {
    projects: Object
  },
    data() {
      return {
        navActive: false,
      };
    },
    setup() {
    const route = useRoute();
    const project = computed(() => {
      const projectId = parseInt(route.params.id);
      return projects.find(p => p.id === projectId) || {};
    });

    return {
      project
    };
  },
    methods: {
      toggleNav() {
        this.navActive = !this.navActive;
      }
    }
  }
  </script>
  
  <style scoped>
 /* @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@900&display=swap');*/
 @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap');
*{
  padding: 0;
  margin: 0;
}

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 1rem;
    right: 0;
    top: 0; /* Aligner en haut */
    left: 0; /* Aligner à gauche */
  position: fixed;
  height: 40px;
  z-index: 100;
  padding-left: 5%;
  padding-right: 5%;
  }
  

  .navbar-brand a {
    text-decoration: none;

  }
  .navbar-brand a p{
    font-family: 'League Spartan', sans-serif;
    font-size: 55px;
    letter-spacing: -3px;
    padding-top: 6px;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 1.4rem;
  }
  
  .navbar-links li a {
    font-weight: bold;
    /*color: #24292F;*/
    text-decoration: none;
    padding: 12px;
    position: relative;
  }

  .navbar-links li a:not(.contact-btn)::after {
  content: '';
  width: 0;
  height: 3px;
  background: #1E90FF;
  position: absolute;
  left: 0;
  bottom: 0px;
  transition: 0.5s;
}

  .navbar-links li a:not(.contact-btn):hover::after {
  width: 100%;
}
  
  .navbar-links li .contact-btn {
  padding: 12px;
  padding-left: 35px;
  padding-right: 35px;
  width: 100px;
  height: 80px;
  background-color: #1E90FF; /* Utilisation de la couleur primaire */
  color: white;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  transition: background-color ease-in 0.3s; /* Transition pour l'effet de survol */
  }
  
  .navbar-links li  .contact-btn:hover {
    background-color: #9333ea; /* Couleur au survol */
    color: white;
    transition: background-color ease-in 0.1s;
  }

  /*BURGER */
  .nav__hamburger {
  display: none;
  cursor: pointer;
  padding: 8px;
  background-color: #19181C;
  border-style: none;

}

.nav__hamburger__line {
  display: block;
  height: 2px;
  width: 30px;
  background-color: white;
  margin: 5px 0;
  transition: all ease-in-out 0.4s;
}

@media screen and (max-width: 768px) {


  .navbar-links li .contact-btn {
    padding: 10px;
    width: auto;
    height: auto; 
    color: white;
    border-radius: 20px;
    font-weight: bold;
    font-size: 18px;
    border: none;
    box-shadow: none; 
    text-align: center; 
    transition: background-color 0.3s ease;
  }
  
.navbar-links li .contact-btn:hover {
  background-color: transparent;
  transition: none;

}

  .navbar-links li{
    padding: 10px;
    width: 100%;
    height: 100%;
    background-color: #19181C;
  }
  .navbar-links li a{
    display: block;
    padding: 10px;
    width: 100%;
    height: 100%;
    font-size: 18px;
  }


.navbar-links li:hover {
  background-color: #1E90FF; /* Change la couleur au survol */
  transform: scale(1.1); /* Légère augmentation de taille */
  transition: all 0.3s ease; /* Transition douce */
  }
  .navbar-links li a:hover {
    color: white; /* Change la couleur du lien au survol */
    /* Pas de transformation ou de changement de taille */
    transition: color ease; /* Transition douce pour le changement de couleur */
  }

  .nav__hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 1px solid white ;
    border-radius: 10px;
  }

  .navbar-links {
    display: none;
    position: absolute;
    background-color: white;
    top: 60px;
    right: 0;
    width: 50%;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .navbar-links.active {
    display: flex;
  }

.nav__hamburger.active .nav__hamburger__line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.nav__hamburger.active .nav__hamburger__line:nth-child(2) {
  opacity: 0;
}

.nav__hamburger.active .nav__hamburger__line:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}
}
  
  </style>