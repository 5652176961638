<template>
  <section id="section4">
    <div class="logo">
        <a href="https://github.com/EnzoDevFrance"><img src="../assets/github.png" alt=""></a>
        <a href="https://www.linkedin.com/in/enzo-poulain-3ab47a198/"><img src="../assets/linkedin.png" alt=""></a>
        <a href="https://fr.fiverr.com/?source=top_nav"><img src="../assets/fiverr.png" alt=""></a>
        <a href="https://www.instagram.com/enzooo.poulain/"><img src="../assets/instagram.png" alt=""></a>
      </div>
      <div class="links">
          <a href="/#section1">Présentation</a>
        <a href="/#section2">À propos</a>
          <a href="/#section3">Portfolio</a>
      </div>
    <div class="infos">
    <p>enzopoulaindev@gmail.com</p>
    <p>07.81.40.64.46</p>
  </div>

  </section>
</template>
  
  <script>
  export default {
    props: {

    },

  };
  </script>
  
   
   <style scoped>
  *{
   padding: 0;
   margin: 0;
  }
  section{
    background-color: #29282E;
    height: 350px;
    width: auto;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 2em;
    position: relative;
    bottom: 0;
  }

 section .logo{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3em;
  }

  section .links{
    display: flex;
    font-size: 22px;
    gap: 30px;
  }

  section .links a{
    color: inherit;
    text-decoration: none;
    font-size: 1.2em;
  }
  section .infos{
    display: flex;
    align-items: flex-start;
    gap: 40px;
  }
  section .infos p{
    font-size: 25px;
  }



  
  /* Pour les écrans moyens - 2 colonnes */
  @media screen and (max-width: 1024px) {
    section .logo{
      gap: 1em;
    }
    section .logo img{
      width: 70%;

    }
    section .links a{
      font-size: 1.2em;
    }
    section .infos p{
      font-size: 1.5em;
    }
  }
  
  /* Pour les petits écrans - 1 colonne */
  @media screen and (max-width: 768px) {
    section .logo{
      gap: 1em;
    }
    section .logo img{
      width: 60%;

    }
    section .links a{
      font-size: 0.9em;
    }
    section .infos{
      flex-direction: column;
      align-items: center;
    }
    section .infos p{
      font-size: 1.5em;
    }
  }
   </style>