<template>
  <section class="banner" id="section1">
    <div class="logo">
        <a href="https://github.com/EnzoDevFrance"><img src="../assets/github.png" alt=""></a>
        <a href="https://www.linkedin.com/in/enzo-poulain-3ab47a198/"><img src="../assets/linkedin.png" alt=""></a>
        <a href="https://fr.fiverr.com/?source=top_nav"><img src="../assets/fiverr.png" alt=""></a>
        <a href="https://www.instagram.com/enzooo.poulain/"><img src="../assets/instagram.png" alt=""></a>
      </div>
    <div class="content-container">
      <h3>I'm Enzo </h3>
      <h3>Your Full-Stack Developer</h3>
      <p>Bonjour et bienvenue sur mon espace WEB. En tant que Développeur Full Stack, je m'applique à concevoir des solutions logicielles élégantes et performantes. Je me spécialise dans la construction d'applications robustes, évolutives et sécurisées avec Spring Boot, combinant backend fiable et sécurité optimisée. Je façonne des expériences utilisateur interactives et intuitives avec Angular, en mettant un point d'honneur sur la réactivité et l'accessibilité.</p>
      <div class="group-button">
      <a href="#section4" class="banner-btn-contact">Contact me</a>
      <button class="banner-btn-cv" @click="downloadPdf" >Télécharger le CV</button>
      </div>
    </div>
    <div class="image-container">
      <img src="../assets/profil1.png" alt="">
    </div>
  </section>
  <section class="aboutme" id="section2">
      <div class="img-about">
        <img src="../assets/profil2.png" alt="">
      </div>
      <div>
        <h3>À propos de moi</h3>
        <p>Diplômé en développement Web et Web mobile, je suis animé par la passion du code et l'ambition d’exceller dans les technologies Java, Spring-Boot et Angular. Je vise à rejoindre une équipe où innover et affiner mes compétences deviennent le quotidien. Avec un solide socle de connaissances et une grande adaptabilité, je suis prêt à relever des défis techniques et à contribuer activement à la réussite de projets ambitieux.

Décidé à construire une carrière significative dans le développement de logiciels, je cherche des opportunités pour faire valoir mon engagement envers le code de qualité et l'innovation technologique.</p>
      </div>
  </section>
  <section id="section3">
    
    <div class="project-cards-container" id="section3">
      <h2>Portfolio</h2>
        <div class="card-layout">
              <ProjectCard class="card" :project="getProjectById(1)"/>
                <div class="regroup">
                  <ProjectCard :project="getProjectById(2)" :hide-description="!isMobile" :hide-git-link="!isMobile" class="column" />
                  <ProjectCard :project="getProjectById(3)" :hide-description="!isMobile" :hide-git-link="!isMobile" class="column" />
                </div>
              <ProjectCard class="card" :project="getProjectById(4)" />
        </div>
  </div>
  </section>
</template>

  

  <script>
  import ProjectCard from '../components/ProjectCard.vue'
  import projects from '../data/projectsData'
  import pdfUrl from '@/assets/CV Enzo POULAIN.pdf';
  export default {
    name: 'HomeView',
  components: {
    ProjectCard, 
    
  },
  
  props: {
    project: Object,
  },
  data() {
    return {
      projects,
      pdfUrl,
      pdfFileName: 'CV Enzo POULAIN.pdf',
      isMobile: false,
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    downloadPdf() {

      const link = document.createElement('a');
      link.href = this.pdfUrl;
      link.target = '_blank';
      link.download = this.pdfFileName;
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  
    goToProject() {
      this.$router.push('/user/name');
    },
    getProjectById(id) {
      return projects.find((project) => project.id === id);
    },

    handleResize() {
      this.isMobile = window.innerWidth <= 1700;
    },
  }

  }
  
  </script>
  
  <style scoped>

  *{
    padding: 0;
    margin: 0;
    background-color:#19181C ;
    box-sizing: border-box;
    overflow-x: hidden;
  }


  .banner {
  display: flex;
  align-items: center;
  padding: 5%;
  gap: 20px; 
  margin-top: 72px;
  height: 90vh;
  overflow: hidden;
  }

.content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  justify-content: center; 
  text-align: left; /* Aligner le texte à gauche */
  flex: 1; 
}

.image-container {
  display: flex;
  justify-content: center;
  flex: 1; /* Donne à l'image-container une largeur flexible */
}
.image-container img{
  height: screen;
}
.banner img {
  width: 500px;
  height: auto; 
}

.banner div h3 {
  font-size: 60px;
  margin-bottom: 10px;
  color: white;
}

.banner div p {
  font-size: 23px;
  margin-bottom: 16px;
  width: 75%;
  color: white;
}

.banner-btn-contact {
  font-size: 15px;
  padding: 13px;
  padding-left: 24px;
  padding-right: 24px;
  width: 140px;
  height: 45px;
  background-color: #1E90FF;
  color: white;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 10px ;
  text-decoration: none
}


.banner-btn-cv {
  height: 45px;
  padding: 10px;
  width: 160px;
  border-color:white;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
color: white; 

}
.banner-btn-cv:hover{
  background-color: #9333ea;
  border-color:#9333ea;
  color: white;
}

.banner-btn-contact:hover {
  background-color: #9333ea; /* Changement de couleur au survol */
}
.banner div button.banner-btn-contact:active {
  transform: scale(0.95); /* Réduit légèrement la taille du bouton */
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2); /* Ajoute un effet d'ombre interne pour le renfoncement */
}
.logo{
  margin-top: 2em;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 40%;
}
.logo a{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}
.logo a img{
  width: 40%;
  height: auto;
}

/* --- Media --- */
@media screen and (max-width: 1900px) {
  .banner img {
    width: 75%;
  }
}
@media screen and (max-width: 1350px) {
  .banner div h3 {
    font-size: 3em;
  }
  .banner div p {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 1024px) {
  .banner div h3 {
    font-size: 3em;
  }

  .banner {
    flex-direction: column-reverse; /* Change la disposition en colonne pour les petits écrans */
    align-items: center; /* Centre les éléments */
    text-align: center;
    height: 100%;
  }

  .content-container, .image-container {
    flex: none; /* Annule la largeur flexible pour les petits écrans */
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .image-container {
    display: none; /* Masque le conteneur de l'image sur les petits écrans */
  }




  .banner div p {
    width: 90%;
    text-align: center;
    font-size: 1.5em;
  }

  .banner div button.banner-btn-contact {
    width: 120px;
    font-size: 14px;
  }
  .logo{
    flex-direction: row;
  }
.logo a{
  justify-content: center;

}
}
@media screen and (max-width: 820px) {
  .banner {
    flex-direction: column-reverse; /* Change la disposition en colonne pour les petits écrans */
    align-items: center; /* Centre les éléments */
    text-align: center;
    height: 100%;
  }

  .content-container, .image-container {
    flex: none; /* Annule la largeur flexible pour les petits écrans */
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .image-container {
    display: none; /* Masque le conteneur de l'image sur les petits écrans */
  }

  .banner img {
    width: 80%;
    max-width: 300px;
  }

  .banner div h3 {
    font-size: 35px;
  }

  .banner div p {
    font-size: 18px;
    width: 90%;
    text-align: center;
  }

  .banner div button.banner-btn-contact {
    width: 120px;
    font-size: 14px;
  }
  .logo{
    flex-direction: row;
  }
.logo a{
  justify-content: center;

}
}


/* -------------------------------- About Me -------------------------------- */

.aboutme{
  display: flex;
  justify-content: center;
  align-items: center; 
  text-align: center; 
  height: 100vh;
  color: white;
}
.aboutme .img-about{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;/* flex-end */

}
.aboutme div img{
  width: 50%;

}
.aboutme div {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  justify-content: center; 
  text-align: left;
}

.aboutme div h3{
  font-size: 2.5em;
  margin-bottom: 1.1em;
}
.aboutme div p{
  width: 80%;
  font-size: 1.6em;
  margin-bottom:12px;
}
.aboutme div button{
  background-color: orange;
  border-radius: 10px;
  padding: 1em;
  color: black;
}

/* Media Query for tablets and below */
@media screen and (max-width: 1024px) {
  .aboutme {
    flex-direction: column;
    height: auto;
    padding: 5%;
  }

  .aboutme .img-about {
    width: 100%;
    margin-bottom: 2em;
  }

  .aboutme .img-about img {
    width: 80%;
    max-width: 400px;
  }

  .aboutme > div {
    width: 100%;
    align-items: center;
  }

  .aboutme div h3 {
    font-size: 2em;
  }

  .aboutme div p {
    font-size: 1.2em;
    width: 100%;
    text-align: center;
  }
}

/* Media Query for phones and below */
@media screen and (max-width: 820px) {
  .aboutme {
    padding: 10% 5%;
  }

  .aboutme .img-about img {
    width: 90%;
  }

  .aboutme div h3 {
    font-size: 1.8em;
  }

  .aboutme div p {
    font-size: 1em;
    text-align: center;
  }

  /* Adjust button styling as needed */

}

/* -------------------------------- PROJECT CARDS -------------------------------- */



 .project-cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12%;
  margin-left: 12%;
  margin-bottom: 3em;
} 
.project-cards-container h2{
  font-size: 45px;
  margin-bottom: 30px;
  color: white;
}

.regroup{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1.5em;

}

.column {
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: auto;
  margin-top: 2em;
}

.card{
  width: 100%;
  margin-top: 2em;
}

.card-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 1684px) {
  .regroup {
    flex-direction: column;
    align-items: center; 
    gap: 0;
  }
  .column {
    flex-direction: row;
    width: 100%;
    margin: 0 auto; 
    margin-top: 2em;
  }
}

@media screen and (max-width: 820px) {
  /* Your existing styles */

 div .regroup {
    flex-direction: column;
    align-items: center; 
    width: 100%;
  }
  .column {
    width: 100%; 
    margin: 0;
    margin-top: 2em;
  }
}


  </style>